@import 'src/theme/theme';

.LocationRow {
  display: flex;
  align-items: flex-start;

  &__icon {
    margin-right: 18px;
  }

  &__subtitle {
  }
}

body .TagChip {
  min-height: 30px;
  padding: 0 16px;
}

body .SectionChip {
  min-height: 24px;
  padding: 5px 12px 2px;
  box-sizing: border-box;
  border-radius: 56px;

  color: $background-color;
  background-color: $secondary-color;

  font-family: $accent-font-family;
  font-size: 26px;
  line-height: 1;
  text-transform: uppercase;
}

.Link {
  color: inherit;
  text-decoration: none;

  &.primary {
    color: $primary-color;
  }
}

body .FiltersRow {
  display: flex;
  align-items: baseline;

  &.swipeable {
    align-items: center;

    .FiltersRow {
      &__title {
        margin-top: 4px;
      }
    }
  }

  &__title {
    margin-right: 16px;
  }

  .Swiper {
    flex: 1;
    margin: 0;

    .SwiperSlide {
      width: auto;
    }
  }
}

body .FilterChip {
  transition: background-color .2s ease-out, color .2s ease-out;

  &:not(.selected) {
    background-color: $background-color;
    border-color: $background-color;

    &:hover {
      background-color: $background-color;
      border-color: $background-color;
    }
  }
}

.TileItem {
  display: flex;
  align-items: center;

  &.small {
    .TileItem__subtitle {
      margin-top: 0;
    }
  }

  &__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__subtitle {
    margin-top: 7px;
    color: $grey-color;
  }
}

@include fDesktop {
  body .TagChip {
    padding: 8px 20px;
  }
  body .SectionChip {
    font-size: 32px;
  }
}

@include sDesktop {
  body .FiltersRow {
    &__title {
      font-size: 19px;
    }
  }
}

@include mobile {
  body .FiltersRow {
    &__title {
      font-size: 16px;
    }
  }
}
