@import 'src/theme/theme';

//@font-face {
//  font-family: 'HelveticaNeue';
//  src: url('assets/fonts/HelveticaNeue.ttf') format("truetype");
//  font-weight: 400;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'HelveticaNeue';
//  src: url('assets/fonts/HelveticaNeue-Medium.ttf') format("truetype");
//  font-weight: 500;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Pribambas';
//  src: url('assets/fonts/Pribambas.ttf') format("truetype");
//  font-weight: 400;
//  font-style: normal;
//  font-display: swap;
//}

body {
  font-family: 'HelveticaNeue', 'Roboto', -apple-system, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  background-color: $background-color;
  color: $text-color;
  font-size: 19px;
  line-height: 24px;
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6, p {
  font-weight: inherit;
}

h1, h2 {
  color: $primary-color;
  font-family: $accent-font-family;
  font-size: 62px;
  line-height: 1;
  letter-spacing: -0.02em;
}

h3 {
  color: $primary-color;
  font-family: $accent-font-family;
  font-size: 35px;
  line-height: 1;
}

h2 {
  color: $primary-color;
  font-family: $accent-font-family;
  font-size: 32px;
  line-height: 1;
}

@include fDesktop {
  h1, h2 {
    font-size: 80px;
  }
  h3, h4 {
    font-size: 48px;
  }
}

@include mDesktop(-20px) {
  h1, h2 {
    font-size: 48px;
  }
  h3, h4 {
    font-size: 26px;
  }
}

@include sDesktop() {
  body {
    font-size: 16px;
  }
}

@include mobile {
  h1, h2 {
    font-size: 40px;
  }
  h3, h4 {
    font-size: 22px;
  }
}
