@import 'src/theme/theme';

body .Footer {
  padding: 98px var(--page-h-margin) 38px;


  background-color: $primary-color;
  color: $background-color;

  &.footer_en {
    .Footer {
      &__row {
        &:first-child {
          justify-content: space-between;
        }
      }

      &__box {
        flex: unset;
        min-width: unset;
        width: auto;
        margin-right: 70px;
      }

      &__resources {
        position: unset;
        top: unset;
        left: unset;
      }

      &__contacts, &__socNetworks {
        margin-right: 0;
      }
    }
  }

  &__row {
    display: flex;

    .Footer__box:last-child {
      margin-right: 24px;
    }

    &:last-child {
      justify-content: flex-start;
      padding-top: 22px;

      .MuiTypography-root {
        font-size: 14px;
      }

      > .MuiTypography-root:first-child {
        margin-right: 14px;
        opacity: 0.4;
      }
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 100px;
    margin-right: 70px;
    margin-bottom: 65px;

    &.Footer {
      &__subscribe, &__socNetworks {
        min-width: 400px;
        width: 38%;
      }

      &__fund {
        flex: 1;
      }
    }

    .MuiTypography-root {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      &.Footer__header {
        margin-bottom: 30px;
      }
    }
  }

  &__subscribeBtn {
    justify-content: space-between;
    min-width: 350px;
    width: 85%;
    max-width: 515px;
    padding: 12px 29px;
    background: rgba(255, 255, 255, 0.1);
    transition: background-color .2s ease-out;
    text-transform: initial;

    &:hover {
      background: rgba(255, 255, 255, 0.14);
    }

    .MuiTypography-root {
      margin-bottom: 0;
    }

    svg {
      margin-top: 4px;

      path {
        stroke: $background-color;
      }
    }
  }

  &__subscribeForm {
    position: relative;
    min-width: 350px;
    width: 85%;
    max-width: 515px;

    input {
      width: 100%;
      min-height: 46px;
      margin-bottom: 8px;
      padding: 4px 50px 0 30px;
      box-sizing: border-box;

      border: 0;
      box-shadow: none;
      border-radius: 60px;
      background: rgba(255, 255, 255, 0.1);
      color: inherit;
      font: inherit;
      outline: none;

      transition: background-color .5s ease-out;

      &::placeholder {
        color: inherit;
      }
    }

    input:-internal-autofill-selected,
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      background-color: rgba(255, 255, 255, 0.1) !important;
      color: $background-color !important;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 16px;
      color: inherit;

      svg path {
        stroke: $background-color;
      }
    }
  }

  &__socNetworks {
    .MuiTypography-root.Footer__header {
      margin-bottom: calc(30px - 6px);
    }
  }

  &__linkBtn {
    width: 50px;
    height: 50px;
    margin-top: 6px;
    margin-right: 6px;
    box-sizing: border-box;
    background-color: $background-color;

    svg path {
      transition: fill 0.25s ease-out;
    }

    &:hover {
      svg path {
        fill: $background-color;
      }
    }
  }
}

body .FooterLink {
  margin-bottom: 8px;
  color: inherit;
  text-decoration: none;
  transition: opacity .2s ease-out;

  &:hover {
    opacity: 0.6;
    //text-decoration: underline;
  }

  &.Footer__lang {
    opacity: 0.6;
    cursor: pointer;

    &:hover {
      opacity: 0.4;
    }
  }

  .grey {
    opacity: 0.6;
  }

  svg {
    margin-left: 10px;
    margin-bottom: -5px;
  }
}

.SubscribeModal {
  .container {
    width: 100vw;
    max-width: 100%;
    min-height: 30vh;
  }
}

@include fDesktop() {
  body .Footer {
    &.footer_en {
      .Footer {
        &__contacts, &__socNetworks {
          margin-right: 80px;
        }
      }
    }

    &__row {
      &:last-child {
        margin-top: -35px;
      }
    }

    &__box {
      &.Footer {
        &__subscribe, &__socNetworks {
          min-width: 620px;
          width: 45%;
        }

        &__fund {
          width: 22%;
        }
      }
    }
  }
}

@media (min-width: 1230px) {
  .SubscribeModal {
    .container {
      max-width: 900px;
    }
  }
}

@media (max-width: 1340px) {
  body .Footer {
    &.footer_en {
      .Footer {
        &__box {
          margin-right: 26px;
        }

        &__contacts, &__socNetworks {
          margin-right: 0;
        }
      }
    }
  }
}

@media (max-width: 1230px) {
  body .Footer {

    &__linkBtn {
      width: 42px;
      height: 42px;
    }

    &__box {
      &.Footer {
        &__subscribe, &__socNetworks {
          min-width: 360px;
        }
      }
    }
  }
}

@media (max-width: 990px) {
  body .Footer {
    padding-bottom: 50px;

    &.footer_en {
      .Footer {
        &__box {
          margin-right: 50px;
        }

        &__contacts, &__socNetworks {
          margin-right: 0;
        }
      }
    }

    &__row {
      position: relative;
      flex-wrap: wrap;

      &:first-child {
        --resources_count: 2;
        //min-height: 250px;
        min-height: calc(250px + 2rem * (var(--resources_count) - 2));
        margin-bottom: 75px;
      }

      &:last-child {
        padding-top: 0;
      }
    }

    &__box {
      margin-bottom: 50px;

      &.Footer {
        &__subscribe, &__socNetworks {
          margin-right: auto;
        }

        &__fund {
          flex: unset;
          min-width: 150px;
          margin-right: 40px;
        }

        &__resources {
          position: absolute;
          top: 160px;
          left: 0;
        }

        &__contacts {
          min-width: 70%;
        }
      }
    }
  }
}


@media (max-width: 720px) {
  body .Footer {
    padding-top: 52px;
    padding-bottom: 30px;
  }
}

@media (max-width: 660px) {
  body .Footer {
    &.footer_en {
      .Footer {
        &__row {
        }

        &__box {
          width: 100%;
          margin-right: 0;
        }
      }
    }

    &__row {
      &:first-child {
        margin-bottom: 0;
      }
    }

    &__box {
      width: 100%;

      &.Footer {
        &__subscribe, &__socNetworks {
          min-width: unset;
          width: 100%;
        }

        &__resources {
          position: initial;
          top: unset;
          left: unset;
        }

        &__contacts {
          padding-right: 16px;
        }
      }

      .MuiTypography-root {
        &.Footer__header {
          margin-bottom: 16px;
        }
      }
    }

    &__socNetworks {
      .MuiTypography-root.Footer__header {
        margin-bottom: calc(16px - 6px);
      }
    }

    &__subscribeBtn {
      min-width: unset;
      width: 100%;
    }

    &__subscribeForm {
      min-width: unset;
      width: 100%;
    }
  }
}
