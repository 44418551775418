@import 'src/theme/theme';

.PageLayout {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  --page-h-margin: #{$l-page-margin};
  --page-bottom-margin: #{$l-page-margin};

  &__container {
    flex: 1 1;

    > .pageContent {
      padding: 0 var(--page-h-margin);

      &:last-child {
        padding-bottom: var(--page-bottom-margin);
      }
    }
  }

}

.Image {
  img {
    max-width: 100%;
    max-height: 100%;

    border-radius: $card-radius;
  }
}

.Photo {
  img {
    max-width: 100%;
    max-height: 100%;

    //background: #FFF;
    border-radius: $card-radius;
    box-shadow: $card-shadow;
  }
}

body .Loader {
  z-index: 2000;
}

@include sDesktop(-10px) {
  .PageLayout {
    --page-h-margin: #{$page-margin};
    --page-bottom-margin: 120px;

    &__container {
    }
  }
}

@include mobile {
  .PageLayout {
    --page-h-margin: #{$m-page-margin};
    --page-bottom-margin: 80px;

    &__container {
    }
  }
}
