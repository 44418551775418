$blue-color: #002169;
$red-color: #B81233;
$dark-color: #262626;
$grey-color: #A1A1A1;

$primary-color: $blue-color;
$secondary-color: $red-color;
$background-color: #fff;

$text-color: $dark-color;
$secondary-text-color: $background-color;
$accent-text-color: rgba(255, 255, 255, 0.3);

$font-family: 'HelveticaNeue', 'Roboto', -apple-system, sans-serif;
$accent-font-family: 'Pribambas', 'HelveticaNeue', 'Roboto', -apple-system, sans-serif;

$card-radius: 8px;
$card-shadow: 0 4px 16px rgba(0, 33, 105, 0.3);

$l-page-margin: 160px;
$page-margin: 48px;
$m-page-margin: 16px;

$mobileMaxWidth: 590px;
$tabletMaxWidth: 768px;
$hTabletMaxWidth: 1024px;
$sDesktopMaxWidth: 1280px;
$mDesktopMaxWidth: 1440px;
$lDesktopMaxWidth: 1600px;
$fDesktopMaxWidth: 1800px;

// Detect mobile screen
// used as
//
// @include mobile {
//     ... mobile screen rules ...
// }
//
@mixin mobile {
  @media (max-width: $mobileMaxWidth) {
    @content;
  }
}

@mixin tablet($delta:0px) {
  @media (max-width: calc(#{$tabletMaxWidth} + #{$delta})) {
    @content;
  }
}

@mixin hTablet {
  @media (max-width: $hTabletMaxWidth) {
    @content;
  }
}

@mixin sDesktop($delta:0px) {
  @media (max-width: calc(#{$sDesktopMaxWidth} + #{$delta})) {
    @content;
  }
}

@mixin mDesktop($delta:0px) {
  @media (max-width: calc(#{$mDesktopMaxWidth} + #{$delta})) {
    @content;
  }
}

@mixin lDesktop {
  @media (max-width: $lDesktopMaxWidth) {
    @content;
  }
}

@mixin fDesktop {
  @media (min-width: 1800px) {
    @content;
  }
}
