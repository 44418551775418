@import 'src/theme/theme';

body .RightArrowButton {
  min-height: 32px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  .MuiTypography-root {
    font-weight: 500;
    font-size: 19px;
  }

  .MuiButton-label {
    margin-bottom: 0;
  }

  &.leftArrow {
    .MuiButton-startIcon {
      transform: rotate(180deg);
    }
  }

  &:hover {
    opacity: 0.4;
    background-color: transparent;
  }

  &.Mui-disabled {
    color: $grey-color;
    background-color: transparent;

    svg path {
      stroke: $grey-color;
    }
  }
}

body .RightArrowIconButton {
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &.Mui-disabled {
    opacity: 0.5;
  }

  &.leftArrow {
    transform: rotate(180deg);
  }
}


@include tablet {
  body .RightArrowButton {
    .MuiTypography-root {
      font-size: 16px;
    }
  }
}
