@import 'src/theme/theme';

.AppBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;

  padding: 28px 0 30px;

  &__logo {
    margin: -18px 0;

    svg {
      max-width: 100%;
      height: auto;
    }
  }

  &__container {
    display: flex;
    align-items: center;

    &.mobile {
      display: none;
      margin-right: 48px;
    }

    nav {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      margin-right: 40px;

      .NavLink {
        margin-left: 28px;
        color: $primary-color;

        &.secondary {
          color: $secondary-color;
        }
      }

      > .NavLink:first-of-type {
        margin-left: 16px;
      }
    }
  }

  .IconButton {
    width: 61px;
    height: 61px;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.NavLink {
  margin-bottom: -5px;
  padding-bottom: 3px;
  border-bottom: 2px solid transparent;
  color: inherit;
  text-decoration: none;
  transition: color .25s ease-out;

  &.active {
    //color: $primary-color;
    border-bottom: 2px solid currentColor;
  }

  &:hover {
    opacity: 0.5;
    //color: $primary-color;
  }
}


.LanguageSwitcher {
  margin: 0 40px 0 0;

  color: $grey-color;
  cursor: pointer;
}


body .RectButton {
  width: 61px;
  height: 61px;

  border-radius: 0;

  .MuiButton-label {
    margin-bottom: 0;
  }
}

.Drawer {
  .paper {
    min-width: 50%;
    padding: $page-margin;
    box-sizing: border-box;
    background-color: $primary-color;
    color: $background-color;
  }

  .IconButton {
    position: absolute;
    top: 28px;
    right: $page-margin;
    width: 61px;
    height: 61px;
  }

  .NavLink {
    margin-bottom: 24px;
    padding: 0;
    border-bottom: none;

    &.active {
      color: $grey-color;
      border-bottom: none;
    }

    &:hover {
      color: $grey-color;
    }
  }

  .LanguageSwitcher {
    margin: 20px 0 0;
  }

  &__socNetworks {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 1322px) {
  .AppBar {
    font-size: 16px;
  }
}

@media (max-width: 1180px) {
  .AppBar {
    &__container {
      nav {
        margin-right: 26px;

        .NavLink {
          margin-left: 20px;
        }
      }

      .LanguageSwitcher {
        margin-right: 26px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .AppBar {
    &__container {
      &.large {
        display: none;
      }

      &.mobile {
        display: flex;
      }
    }
  }
}

@include tablet {
  .Drawer {
    .paper {
      width: 100%;

      font-size: 19px;
    }
  }
}

@include mobile {
  .AppBar {
    &__logo {
      max-width: 188px;
    }

    &__container {
      &.mobile {
        display: flex;
        margin-right: 16px;

        .IconButton {
          width: 48px;
          height: 48px;
        }
      }
    }
  }

  .Drawer {
    .paper {
      padding: $page-margin $m-page-margin;

      font-size: 16px;
    }

    .IconButton {
      right: $m-page-margin;
      width: 48px;
      height: 48px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    .NavLink {
      margin-bottom: 16px;
    }

    .LanguageSwitcher {
      margin-top: 16px;
    }
  }
}
