@import 'src/theme/theme';

.Calendar {

}

.eventTime {
  position: absolute;
  top: -34px;
  left: 0;
  //background-color: $background-color;

  &:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 6px;
    bottom: 6px;
    z-index: -1;
    content: ' ';
    background-color: $background-color;
  }
}

.TimeLine {
  position: relative;
  z-index: 1;

  .eventTime {
    //top: -12px;
    color: $grey-color;
  }
}

body .SectionCard {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 84px;
  padding: 26px 20px 16px;
  box-sizing: border-box;
  background-color: $background-color;
  border-radius: $card-radius;
  box-shadow: $card-shadow;

  font-size: 14px;
  line-height: 1.2;

  cursor: pointer;
  //user-select: initial;
  -webkit-tap-highlight-color: transparent;

  &.coffeeBreak {
    min-height: 58px;
    line-height: 17px;
    background-color: transparent;

    cursor: auto;

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      display: block;
      content: ' ';
      width: 64%;

      background: url('../../assets/images/coffee_break.png') no-repeat right top;
      background-size: contain;
    }

    &.smallImage {
      padding-top: 22px;

      &:before {
        background-image: url('../../assets/images/coffee_break_small.png');
      }
    }

    &.oneLine {
      padding: 14px 20px;
    }

    &.large {
      &:before {
        width: 72%;
        background-image: url('../../assets/images/coffee_break_large.png');
      }
    }
  }


  &.small {
    .SectionCard {
      &__title {
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        max-height: calc(1.2 * 2rem);
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.oneLine {
    justify-content: center;
    min-height: 42px;
    padding: 14px 20px;

    .SectionCard {
      &__title {
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        max-height: calc(1.2 * 1rem);
        line-clamp: 1;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.medium {
    .SectionCard {
      &__title {
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        max-height: calc(1.2 * 5rem);
        line-clamp: 5;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__name {
    position: absolute;
    top: -5px;
    font-size: 16px !important;
  }

  &__title {

  }

  &__speeches {
    max-height: calc(100% - 2 * 20px);
    overflow: auto;
    margin: 20px 0;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #F2F4F8;
  }

  .SpeakerCard {
    margin-top: 6px;

    :global(.subtitle) {
      color: $primary-color;
    }
  }
}

body .Speech {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &__time {
    margin-bottom: 8px;
    opacity: 0.5;
  }
}

.SpeakersRow {
  .SpeakerCard {
    &__image {
      margin-left: -13px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

body .SpeakerCard {
  &.twoLine {
  }

  &.small {
    .SpeakerCard__image {
      width: 34px;
      height: 34px;

      &:last-child {
        margin-right: 12px;
      }
    }
  }

  &.medium {
    .SpeakerCard__image {
      width: 44px;
      height: 44px;

      &:last-child {
        margin-right: 12px;
      }
    }
  }

  &__image {
    width: 60px;
    height: 60px;

    &:last-child {
      margin-right: 20px;
    }

    img {
      width: 100%;
      height: 100%;
      max-width: unset;
      max-height: unset;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.MobileSchedule {
  .card {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .SectionCard {
    margin-top: 9px;
  }
}

@include tablet {
  body .SpeakerCard {
    &.twoLine {
      align-items: flex-start;

      :global(.title) {
        margin-top: 5px;
      }
    }

    &.small, &.medium {
      :global(.title) {
        margin-top: 0;
      }
    }
  }
}

@include mobile {
  body .SectionCard {

    //&.coffeeBreak {
    //  &.large {
    //    height: auto;
    //    padding-top: 22px;
    //
    //    &:before {
    //      background-image: url('../../assets/images/coffee_break_small.png');
    //    }
    //  }
    //}
  }
}
