@import 'src/theme/theme';

body .Drawer {
  --h-margin: 55px;

  .paper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 28px;
    box-sizing: border-box;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100vh;
    background-color: $primary-color;

    &.no_result {
      min-height: 770px;
    }
  }

  .closeBtn {
    --size: 61px;
    width: var(--size);
    height: var(--size);
    margin: 0 var(--h-margin) 28px;
  }

  &__input {
    justify-content: center;
    min-height: 61px;
    margin: 0 var(--h-margin);

    border-radius: $card-radius;
    background-color: $background-color;
    font-size: 25px;

    :global(.MuiInputBase-root) {
      flex: 1;
      border-radius: $card-radius;

      &:before, &:after {
        display: none;
      }
    }

    input {
      padding: 14px 22px;
      background-color: $background-color;
      border-radius: $card-radius;
      font-size: inherit;
    }

    :global(.MuiInputBase-adornedStart) {
      padding-left: 22px;
    }

    :global(.MuiInputBase-root),
    :global(.MuiInputBase-root:hover),
    :global(.Mui-focused) {
      background-color: $background-color;
      font-size: inherit;
    }
  }

  .suggestions {
    margin: calc(60px - 8px) var(--h-margin) 0;
    color: $background-color;

    &__title {
      margin-bottom: 24px;
      font-size: 19px;
    }

    .chip {
      margin-top: 8px;
      margin-right: 8px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    --page-margin: #{$l-page-margin};
    padding: 100px var(--page-margin) $l-page-margin;

    span {
      &:global(.highlighted) {
        position: relative;
        z-index: 1;

        &:before {
          position: absolute;
          top: -4px;
          bottom: -4px;
          left: -2px;
          right: -2px;
          z-index: -1;
          content: ' ';

          background-color: #f2f4f8;
        }
      }
    }

    .SearchCard {
      margin-bottom: 32px;
    }
  }

  .noResult {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;
    margin: 64px $m-page-margin 0;

    color: $background-color;
    text-align: center;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      //max-width: 475px;
      margin-top: 50px;
    }

    &__hint {
      max-width: 300px;
      margin-top: 14px;
      font-size: 14px;
    }
  }
}

.SearchCard {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  width: 100%;
  max-width: 1120px;
  min-height: 264px;

  background-color: $background-color;
  border-radius: $card-radius;
  box-shadow: $card-shadow;

  --image-width: 350px;

  &__container {
    flex: 5;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px;
  }

  &.hasImage {
    .SearchCard__container {
      margin-right: var(--image-width);
    }
  }

  &__image {
    flex: 2;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: var(--image-width);
    //max-width: 350px;

    img {
      max-width: unset;
      max-height: unset;
      width: 100%;
      height: 100%;
      //object-position: right top;
      object-fit: cover;
      border-radius: 0 $card-radius $card-radius 0;
    }
  }

  .speakersRow {
    margin-top: 14px;
    font-size: 16px;
  }

  .subtitle {
    margin-top: 4px;
  }

  .small {
    margin-top: 8px;
  }
}

.SpeakerCard {
}

.ProgramCard {
  .location {
    font-size: 16px;

    .small {
      margin-top: 0;
    }
  }
}

.ProgramSectionCard {
}

body .Chip {
  min-height: 30px;
  margin-bottom: 22px;
  padding: 0 16px;

  &:hover {
    background-color: $secondary-color;
    cursor: default;
  }
}

.Text {
  &.small {
    color: $grey-color;
    font-size: 14px;
    line-height: 20px;
  }
}

@include fDesktop() {
  body .Chip {
    padding: 8px 20px;
  }

  .Text {
    &.small {
      font-size: 16px;
    }
  }
}

@include sDesktop() {
  body .Drawer {
    &__input {
      font-size: 19px;
    }

    .suggestions {
      margin-top: calc(50px - 8px);
    }

    .container {
      padding-top: 80px;
      padding-bottom: 120px;
    }
  }

  .SearchCard {
    --image-width: 300px;
  }
}

@include sDesktop(-10px) {
  body .Drawer {
    .container {
      --page-margin: #{$page-margin};
    }
  }
}

@include tablet() {
  body .Drawer {
    --h-margin: #{$page-margin};

    .suggestions {
      margin-top: calc(30px - 8px);
    }

    .container {
      padding-top: 50px;
    }
  }

  .SearchCard {
    --image-width: 234px;
  }
}

@include mobile() {
  body .Drawer {
    --h-margin: #{$m-page-margin};

    .paper {
      padding-top: 26px;
    }

    .closeBtn {
      --size: 48px;
      margin-bottom: 26px;
    }

    &__input {
      min-height: 48px;
      font-size: 16px;
    }

    .suggestions {
      display: flex;
      flex-direction: column;
      align-items: center;

      .chip {
        margin: 0 0 30px;
      }
    }

    .container {
      --page-margin: #{$m-page-margin};
      padding-top: 30px;
      padding-bottom: 80px;
    }
  }

  .SearchCard {
    flex-direction: column;
    min-height: 490px;
    --image-width: unset;
    --image-height: 232px;

    &.hasImage {
      .SearchCard__container {
        margin-right: 0;
        margin-top: var(--image-height);
      }
    }

    &__container {
      padding: 22px;
    }

    &__image {
      left: 0;
      bottom: unset;
      height: var(--image-height);

      img {
        object-position: center 14%;
        border-radius: $card-radius $card-radius 0 0;
      }
    }
  }

  body .Chip {
    margin-bottom: 18px;
  }
}
