@import 'src/theme/theme';

.Dialog {
  .closeBtn {
    position: absolute;
    top: -61px;
    right: -61px;
    width: 61px;
    height: 61px;
    z-index: 1000;

    background-color: $background-color;
    box-shadow: $card-shadow;

    &:hover {
      background-color: rgba(255, 255, 255, 0.74);
    }

    svg path {
      stroke: $primary-color;
    }
  }
}

@include tablet {
  .Dialog {
    .closeBtn {
      top: calc(100% + 32px);
      right: calc(50% - 30px);
    }
  }
}

@include mobile {
  .Dialog {
    .closeBtn {
      position: fixed;
      top: calc(100% - 48px - 30px);
      right: calc(50% - 24px);
      width: 48px;
      height: 48px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
